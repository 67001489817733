@import "/src/assets/scss/variable";

// .dashboard_page {
//     margin: 30px 0 0 0;
//     .stat_row_area {
//         display: flex;
//         flex-wrap: wrap;
//         column-gap: 15px;
//         row-gap: 15px;
//         .stat_single {
//             min-width: calc((100% - 30px)/ 3);
//             .card {
//                 height: 160px;
//                 .ant-card-body {
//                     .stat_card_title {
//                         display: flex;
//                         align-items: center;
//                         font-size: 20px;
//                         line-height: 24px;
//                         font-weight: 700;
//                         .icon {
//                             font-size: 30px;
//                             color: $primary;
//                             margin-left: auto;
//                         }
//                     }
//                     .details {
//                         font-size: 15px;
//                         margin: 10px 0 0 0;
//                     }
//                     .row {
//                         display: flex;
//                         align-items: center;
//                         margin: 15px 0 0 0;
//                         .ant-btn {
//                             padding: 0 10px;
//                             height: 32px;
//                             border: 1px solid $black3a;
//                             border-radius: 5px;
//                             font-size: 13px;
//                             line-height: 32px;
//                             color: $black3a;
//                             font-weight: 400;
//                         }
//                         .info_icon {
//                             font-size: 20px;
//                             color: $text-gray;
//                             margin-left: auto;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// Dashboard
.dashboard {
  width: 100%;

  .ant-row {
    position: relative;
    display: flex;

    .ant-tabs {
      box-shadow: 0 4px 4px rgba($black, 0.1);
      height: 54px;
      width: 100%;

      .ant-tabs-nav {
        height: 100%;

        &:before {
          border: unset;
        }

        .ant-tabs-nav-wrap {
          padding: 0 90px;

          .ant-tabs-nav-list {
            .ant-tabs-tab {
              color: $black3a;
            }

            .ant-tabs-tab-active {
              border-bottom: 3px solid $primary;

              .ant-tabs-tab-btn {
                color: $primary;
                font-weight: 500;
              }
            }
          }
        }
      }

      .ant-tabs-content-holder {
        .ant-tabs-content {
          .dashboard_tabs {
            .main_content {
              padding: 0 90px;
              min-height: auto;
            }
          }
        }
      }
    }
  }

  .copy_clipboard_btn {
    position: absolute;
    right: 90px;
    height: 38px;
    padding: 0 12px;
    border: 1px solid $black83;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    color: $black83;

    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 17px;

      svg {
        font-size: 14px;
        color: $black83;
        margin-right: 5px;
      }
    }
  }
}

.gray_bg {
  background-color: #f6f6f6;
  margin-bottom: 35px;

  .ant-layout-content {
    background-color: #f6f6f6;

    .main_content {
      padding: 0 90px;
    }
  }
}

.my_dashboard {
  padding: 0 90px;
  margin-bottom: 23px !important;

  .title_area {
    margin: 23px 0 0 0;
    align-items: center;

    .col_left {
      h3 {
        font-size: 20px;
        line-height: 24px;
        display: block;
        margin: 0 0 4px 0;
      }

      p {
        font-size: 14px;
        display: block;
        margin: 4px 0 0 0;
      }
    }

    .col_right {
      margin-left: auto;
      display: flex;
      align-items: center;

      label {
        font-size: 14px;
        margin: 0 10px 0 0;
      }

      .data_select {
        .ant-select-selector {
          align-items: center;
          border-radius: 6px !important;
          width: 200px;
          height: 38px !important;
          padding: 3px 11px;
        }
      }
    }
  }

  .ant-row {
    .ant-layout-content {
      margin-bottom: 20px;

      .main_content {
        padding: 0;

        .stat_page {
          margin: 25px 0 0 0;
          padding: 0 0 32px 0;
          border-bottom: 1px solid $card-border;
        }
      }
    }
  }

  .leaderboard_stat_area {
    column-gap: 15px;
    margin-bottom: 30px;

    .left_col,
    .right_col {
      width: calc((100% - 15px) / 2);

      .col_title {
        font-size: 17px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin: 0 0 14px 0;
        height: 40px;

        .info_icon {
          font-size: 20px;
          margin-left: 7px;
          color: $text-gray;
        }
      }

      .col_wrapper {
        border: 1px solid #e4e4e4;
        border-radius: 7px;
        padding: 5px 20px;
      }
    }

    .left_col {
      .col_wrapper {
        min-height: 300px;
        max-height: 300px;
        overflow: auto;

        .leaderboard_table {
          thead {
            tr {
              th {
                background-color: unset;
                font-size: 15px;
                font-weight: 500;

                &:before {
                  display: none;
                }
              }
              .id_column {
                width: 60px;
              }
            }
          }

          tbody {
            tr {
              td {
                .user_avatar {
                  margin: 0 10px 0 0;
                }
              }
            }
          }
        }
      }
    }

    .right_col {
      .col_title {
        .filter_area {
          margin-left: auto;
          position: relative;

          small {
            font-weight: normal;
          }

          .ant-select {
            .ant-select-selector {
              align-items: center;
              border: 1px solid #e4e4e4 !important;
              height: 40px !important;
              width: 220px;
              padding-top: 4px;
              margin-left: 10px;
            }
          }

          .help_area {
            position: absolute;
            right: 0;
            top: -23px;

            .help_label {
              font-size: 11px;
              margin: 0 3px 0 0;
            }

            .help_icon {
              width: 24px;
              border: 1px solid #e4e4e4;
              border-radius: 100px;
              cursor: pointer;
            }
          }
        }
      }

      .col_wrapper {
        border: unset;
        padding: 0;
        display: flex;
        column-gap: 15px;
        row-gap: 15px;
        flex-wrap: wrap;

        .card {
          width: calc((100% - 15px) / 2);
          border: 1px solid #e4e4e4;

          .ant-card-body {
            padding: 20px;
            position: relative;

            .stat_card_title {
              font-size: 20px;
              line-height: 24px;
              font-weight: 500;
              margin: 0 0 2px 0;
            }

            .details {
              margin: 0;
            }

            .info_icon {
              position: absolute;
              color: $text-gray;
              font-size: 20px;
              bottom: 10px;
              right: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.w-100 {
  width: 100%;
}

.left-align {
  text-align: left;
}

.left-align-md {
  text-align: left;
}

.right-align {
  text-align: right;
}

.right-align-md {
  text-align: right;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.m-0 {
  margin: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

// Responsive
@media only screen and (max-width: 1000px) {
  .my_dashboard {
    .leaderboard_stat_area {
      display: block;

      .left_col {
        width: 100%;
        margin: 0;
      }

      .right_col {
        width: 100%;
        margin: 30px 0 0 0;
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  /* 800px */
  .my_dashboard {
    padding: 0 40px;
  }
  .dashboard {
    .ant-row {
      .ant-tabs {
        .ant-tabs-content-holder {
          .ant-tabs-content {
            .dashboard_tabs {
              .main_content {
                padding: 0 40px;
              }
            }
          }
        }

        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            padding: 0 40px;
          }
        }
      }
    }

    .copy_clipboard_btn {
      right: 40px;
    }
  }
  .stat_page {
    .stat_row_area {
      .stat_single {
        margin-top: 12px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .my_dashboard {
    padding: 0 20px;
    margin-bottom: 18px !important;

    .title_area {
      flex-wrap: unset;
      align-items: center;
      margin: 17px 0 0 0;

      .col_left {
        h3 {
          font-size: 16px;
          line-height: 18px;
          margin: 2px 0 2px 0;
        }

        div {
          font-size: 13px;
        }
      }

      .col_right {
        label {
          display: none;
        }

        .data_select {
          .ant-select-selector {
            width: 130px;
            min-height: 36px;
            max-height: 36px;
          }
        }
      }
    }

    .leaderboard_stat_area {
      .left_col {
        margin-top: 12px;

        .col_title {
          margin: 0 0 7px 0;
          font-size: 16px;
        }

        .col_wrapper {
          padding: 0 5px;
          min-height: inherit;
          max-height: inherit;
        }
      }

      .right_col {
        margin: 15px 0 0 0;

        .col_title {
          font-size: 16px;

          .filter_area {
            small {
              display: none;
            }

            .ant-select {
              .ant-select-selector {
                width: 130px;
                min-height: 36px;
                max-height: 36px;
                padding-top: 0;
              }
            }
          }
        }

        .col_wrapper {
          .card {
            .ant-card-body {
              padding: 8px 25px 10px 10px;
              height: 100%;

              .stat_card_title {
                font-size: 15px;
                margin: 0;
              }

              .details {
                font-size: 13px;
                line-height: 14px;
              }

              .info_icon {
                font-size: 16px;
                bottom: 6px;
                right: 6px;
              }
            }
          }
        }
      }
    }
  }
  .dashboard {
    .ant-row {
      .ant-tabs {
        .ant-tabs-content-holder {
          .ant-tabs-content {
            .dashboard_tabs {
              .main_content {
                padding: 0 20px;
              }
            }
          }
        }

        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            padding: 0 20px;
          }
        }
      }
    }

    .copy_clipboard_btn {
      display: none;
    }
  }
  .stat_page {
    .stat_row_area {
      .stat_single {
        .card {
          .ant-card-body {
            .stat_card_title {
              font-size: 16px;

              .icon {
                width: 23px;
                max-height: 20px;
              }
            }

            .details {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .gray_bg {
    margin-bottom: 0px;

    main {
      margin-bottom: 20px;
    }
  }
  .right-align-md {
    text-align: left;
  }
}

@media only screen and (max-width: 769px) {
  .stat_page {
    .stat_row_area {
      .stat_single {
        margin-top: 12px;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .dashboard {
    .ant-row {
      .ant-tabs {
        .ant-tabs-content-holder {
          .ant-tabs-content {
            .dashboard_tabs {
              .main_content {
                padding: 0 20px;
              }
            }
          }
        }

        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            padding: 0 20px;

            .ant-tabs-nav-list {
              justify-content: space-between;
              width: 100%;

              .ant-tabs-tab {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
