.footer {
  position: fixed;
  background-color: $footer-bg;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  left: 0;
  bottom: 0;
  z-index: 1;

  span {
    font-size: 14px;
    line-height: normal;
    font-weight: $w4;
  }

  img {
    height: 18px;
    margin: 0 0 0 10px;
  }
}
