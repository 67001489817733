@import '/src/assets/scss/variable';

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 16px;
}

.personal-detail-first-row {
  .ant-input {
    border-radius: 5px;
    padding-top: 10px;
  }
  .personal-detail-name-col {
    padding: 4px;
  }

  .personal-detail-email-col {
    padding: 4px;
  }
}

.personal-detail-second-row {
  .ant-input {
    border-radius: 5px;
  }

  .personal-detail-mobile-col {
    padding-top: 10px;

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      color: #333;
    }

    input:focus {
      outline: none;
      border-color: #80bdff;
    }
  }
}

.personal-detail-country-col {
  padding-top: 10px;
}

.personal-detail-country-col .ant-select-selector {
  background-color: #fff;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
}

.personal-detail-country-col .ant-select {
  // padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.personal-detail-country-col .ant-select-arrow {
  color: #333;
}

.title {
  font-size: 16px;
  font-weight: 400;
}

.course-details-programme-row {
  .course-details-programme-col {
    padding: 4px;
    .programme-select {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .course-details-course-col {
    padding: 4px;
    .course-select {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.course-details-note-row {
  .course-details-note-col {
    padding: 4px;

    .admin-note-area {
      resize: none;
    }
  }
}

.course-details-check-row {
  .course-details-check-col {
    padding: 4px;

    .invite-text {
      margin-left: 8px;
    }
  }
}

.course-details-invite-row {
  margin-bottom: 10px;
  .course-details-invite-col {
    padding: 4px;

    .lead-note-area {
      resize: none;
    }
  }
}

.navigation-main-row {
  .cancel-col {
    display: flex;
    .cancel-button {
      border: none !important;
    }
  }

  .next-col {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    .next-button {
      background: $primary;
      color: #fff;
    }

    .back-button {
      border: none !important;
    }

    .add-lead-button {
      background: $primary;
      color: #fff;
    }
  }
}
